import { DateTime, Duration, DurationObjectUnits, Interval } from 'luxon';

export { Duration } from 'luxon';

export type { DurationObjectUnits } from 'luxon';

export enum DateFormat {
  US_DATE = 'MM/dd/yyyy',
  SHORT_US_DATE = 'MMM d, yyyy', // Jan 1, 2022
  // This formats does not matter because are overriden by `toISODate` and `toISO` methods
  ISO_DATE = 'yyyy-MM-dd',
  ISO_DATE_TIME = 'yyyy-MM-dd HH:mm:ss',
}
/**
 * Value object that holds logic of converting dates between APP and API formats
 * Format is optional parameter, default is US_DATE_FORMAT 'MM/dd/yyyy'
 */
export default class Datos {
  static Formats = DateFormat;
  private constructor(private datosDate: DateTime) {}

  static now(): Datos {
    return new Datos(DateTime.utc());
  }

  static today(): Datos {
    return new Datos(DateTime.utc().startOf('day'));
  }

  static fromISODate(date: string): Datos {
    return new Datos(DateTime.fromISO(date, { zone: 'utc' }));
  }

  static fromJSDate(date: Date): Datos {
    return new Datos(DateTime.fromJSDate(date));
  }

  static from(date: string, format: DateFormat = DateFormat.ISO_DATE): Datos {
    switch (format) {
      case DateFormat.ISO_DATE:
        return new Datos(DateTime.fromISO(date, { zone: 'utc' }));
      case DateFormat.ISO_DATE_TIME:
        return new Datos(DateTime.fromISO(date));
      default:
        return new Datos(DateTime.fromFormat(date, format));
    }
  }

  static fromISOInterval(interval: string): { start: Datos; end: Datos } {
    const luxonInterval = Interval.fromISO(interval, { setZone: true });

    return {
      start: new Datos(luxonInterval.start),
      end: new Datos(luxonInterval.end),
    };
  }

  get isValid() {
    return this.datosDate.isValid;
  }

  diff(date: Datos, unit: keyof DurationObjectUnits): Duration {
    return this.datosDate.diff(date.datosDate, unit);
  }

  toFormat(format: DateFormat = DateFormat.ISO_DATE): string {
    switch (format) {
      case DateFormat.ISO_DATE:
        return this.datosDate.toISODate();
      case DateFormat.ISO_DATE_TIME:
        return this.datosDate.toISO();
      default:
        return this.datosDate.toFormat(format);
    }
  }

  toJSDate(): Date {
    return this.datosDate.toJSDate();
  }

  get day() {
    return this.datosDate.day;
  }

  get year() {
    return this.datosDate.year;
  }

  get month() {
    return this.datosDate.month;
  }

  get minute() {
    return this.datosDate.minute;
  }

  get hour() {
    return this.datosDate.hour;
  }
}

import { useRescindPolicyMutation } from '@graphql/api';
import { ErrorCode, logError } from '@services/logging';
import StatusAlert from '@componentsV2/StatusAlert';
import { Text, TextHeading } from '@componentsV2/Typography';
import Button from '@componentsV2/Button';
import ButtonGroup from '@componentsV2/ButtonGroup';

type Props = {
  userId: string;
  policyNumber: string;
  onClose: () => void;
  className?: string;
};

const RescindPolicy: React.FC<Props> = ({
  userId,
  policyNumber,
  onClose,
  className,
}) => {
  const {
    isLoading,
    isError,
    isSuccess,
    mutate: rescindPolicy,
  } = useRescindPolicyMutation({
    onError: (e: Error) => logError(ErrorCode.UserPolicyRescind, e, { userId }),
  });

  return (
    <div className={className}>
      <TextHeading>Rescind policy</TextHeading>
      <Text>
        Please confirm you would like to rescind policy{' '}
        <span className="font-bold">{policyNumber}</span> for user{' '}
        <span className="font-bold">{userId}</span>
      </Text>
      <ButtonGroup className="mt-6">
        <Button
          disabled={isLoading}
          onClick={() => rescindPolicy({ input: { userId } })}
        >
          Rescind policy
        </Button>
        <Button variant="transparent" onClick={onClose}>
          Cancel
        </Button>
      </ButtonGroup>
      <StatusAlert
        status={
          isLoading
            ? 'info'
            : isError
            ? 'error'
            : isSuccess
            ? 'success'
            : undefined
        }
        infoMessage="Rescinding policy..."
        errorMessage="There was an error rescinding policy."
        successMessage="Successfully rescinded policy."
        className="mt-6"
      />
    </div>
  );
};

export default RescindPolicy;

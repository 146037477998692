import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import ErrorMessage from '@componentsV2/Inputs/ErrorMessage';
import Label from '@componentsV2/Inputs/Label';
import { default as SelectField, Option } from '@componentsV2/Inputs/Select';

type Props<T extends FieldValues, U> = UseControllerProps<T> & {
  label?: string;
  placeholder?: string;
  className?: string;
  options: Option<U>[];
};

const Select = <T extends FieldValues, U>({
  label,
  placeholder,
  className,
  options,
  ...props
}: Props<T, U>) => {
  const {
    field: { ref, ...fieldProps },
    fieldState,
  } = useController(props);

  return (
    <div className={className}>
      {label ? (
        <div className="mb-1">
          <Label htmlFor={fieldProps.name}>{label}</Label>
        </div>
      ) : null}
      <SelectField
        placeholder={placeholder}
        {...fieldProps}
        inputRef={ref}
        options={options}
        className={fieldState.error ? 'border-red' : ''}
      />
      {fieldState.error ? (
        <ErrorMessage message={fieldState.error.message} className="mt-1" />
      ) : null}
    </div>
  );
};

export default Select;

export interface VehicleBase {
  make: string;
  model: string;
  vin: string;
  year: number;
}

export class Vehicle implements VehicleBase {
  private constructor(
    public id: string,
    public make: string,
    public model: string,
    public vin: string,
    public year: number,
    public lienholder: Vehicle.Lienholder | null,
  ) {}

  static create(
    vehicle: { id: string } & VehicleBase,
    lienholder: Vehicle.Lienholder | null = null,
  ) {
    return new Vehicle(
      vehicle.id,
      vehicle.make,
      vehicle.model,
      vehicle.vin,
      vehicle.year,
      lienholder,
    );
  }
}

export namespace Vehicle {
  export interface Lienholder {
    name: string;
    email: string | null;
    phone: string | null;
  }
}

import { UserSummary } from './types';
import {
  PhoneIcon,
  CakeIcon,
  MapPinIcon,
  MapIcon,
  UserIcon,
  CreditCardIcon,
} from '@heroicons/react/24/outline';
import SectionToggle from '@componentsV2/SectionToggle';
import Divider from '@componentsV2/Divider';

type Props = {
  userSummary: UserSummary;
};

const SummaryContent: React.FC<Props> = ({ userSummary }) => {
  const {
    phoneNumber,
    dob,
    address,
    driverLicenseNumber,
    autoTripEnabled,
    autoPayEnabled,
  } = userSummary;

  return (
    <SectionToggle title="Summary">
      <SummaryItem Icon={PhoneIcon}>{phoneNumber}</SummaryItem>
      <SummaryItem Icon={CakeIcon}>{dob}</SummaryItem>
      <SummaryItem Icon={MapPinIcon}>{address}</SummaryItem>
      <SummaryItem Icon={UserIcon}>{driverLicenseNumber}</SummaryItem>
      <Divider variant="dashed" className="mb-3" />
      <SummaryItem Icon={MapIcon}>
        Auto trip detection:{' '}
        <span className="font-bold">{autoTripEnabled ? 'On' : 'Off'}</span>
      </SummaryItem>
      <SummaryItem Icon={CreditCardIcon}>
        Auto pay:{' '}
        <span className="font-bold">{autoPayEnabled ? 'On' : 'Off'}</span>
      </SummaryItem>
    </SectionToggle>
  );
};

const SummaryItem: React.FC<{ Icon: HeroIcon }> = ({ Icon, children }) => (
  <div className="flex items-center mb-3">
    <Icon className="w-4 h-4 text-cloudy-blue mr-1" />
    <div className="flex-1 text-xs text-slate">{children}</div>
  </div>
);

export default SummaryContent;

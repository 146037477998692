import { twMerge } from 'tailwind-merge';

type Props = {
  variant?: 'dashed' | 'solid';
  className?: string;
};

const Divider: React.FC<Props> = ({ variant = 'solid', className }) => (
  <div
    className={twMerge(
      'border-b border-light-grey',
      variant === 'dashed' ? 'border-dashed' : '',
      className,
    )}
  />
);

export default Divider;

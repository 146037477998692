import { useCancelUserPolicyMutation } from '@graphql/api';
import { ErrorCode, logError } from '@services/logging';
import { schema, FormValues } from './schema';
import Form from '@componentsV2/Form/Form';
import Button from '@componentsV2/Button';
import StatusAlert from '@componentsV2/StatusAlert';
import ButtonGroup from '@componentsV2/ButtonGroup';
import { Text, TextHeading } from '@componentsV2/Typography';
import CancellationReasonsSelect from './CancellationReasonsSelect';

type Props = {
  userId: string;
  policyNumber: string;
  onClose: () => void;
  className?: string;
};

const CancelByCarrier: React.FC<Props> = ({
  userId,
  policyNumber,
  onClose,
  className,
}) => {
  const {
    mutate: cancelPolicy,
    isLoading,
    isError,
    isSuccess,
  } = useCancelUserPolicyMutation({
    onError: (e: Error) => logError(ErrorCode.UserPolicyCancel, e, { userId }),
  });

  return (
    <div className={className}>
      <TextHeading>Cancel by carrier</TextHeading>
      <Text>
        Please confirm you would like to cancel policy{' '}
        <span className="font-bold">{policyNumber}</span> for user{' '}
        <span className="font-bold">{userId}</span>
      </Text>
      <Form<FormValues>
        defaultValues={{
          cancellationExplanationCode: undefined,
        }}
        schema={schema}
        onSubmit={({ cancellationExplanationCode }) =>
          cancelPolicy({
            input: {
              userId,
              cancellationExplanationCode,
            },
          })
        }
      >
        <CancellationReasonsSelect />
        <ButtonGroup className="mt-6">
          <Button variant="alert" type="submit" disabled={isLoading}>
            Confirm cancellation
          </Button>
          <Button variant="transparent" onClick={onClose} type="button">
            Cancel
          </Button>
        </ButtonGroup>
        <StatusAlert
          status={
            isLoading
              ? 'info'
              : isError
              ? 'error'
              : isSuccess
              ? 'success'
              : undefined
          }
          infoMessage="Cancelling policy..."
          errorMessage="There was an error cancelling policy."
          successMessage="Successfully cancelled policy."
          className="mt-6"
        />
      </Form>
    </div>
  );
};

export default CancelByCarrier;

import { UserPolicy } from '../../types';
import { addressToString } from '@helpers/addressHelpers';
import { PolicyCardType } from './types';
import { Text } from '@componentsV2/Typography';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

type Props = {
  policy: UserPolicy;
  policyCardType: PolicyCardType;
  onViewFullBreakdown: () => void;
};

const PolicyInfo: React.FC<Props> = ({ policy, onViewFullBreakdown }) => (
  <>
    <Row>
      <div>{policy.policyholder.name}</div>
      <div className="col-span-3">
        {addressToString(policy.policyholder.address)}
      </div>
    </Row>
    {policy.vehicles.map(({ year, make, model, vin }, index) => (
      <Row key={vin}>
        <div>Vehicle {index + 1}</div>
        <div>
          {year}, {make}, {model}
        </div>
        <div>{vin}</div>
      </Row>
    ))}
    <Row>
      <div>
        <Text>{policy.packageName}</Text>
        <div className="flex">
          <div
            className="cursor-pointer text-teal text-sm flex items-center gap-1"
            onClick={onViewFullBreakdown}
          >
            View full breakdown
            <ArrowRightIcon className="w-4 h-4" />
          </div>
        </div>
      </div>
      <div>
        <Text>{policy.premium.perMile.toFormat()} per mile</Text>
        <Text>{policy.premium.perDay.toFormat()} per day</Text>
      </div>
    </Row>
    <Row>
      <Text>Included</Text>
      {policy.coverages.includedCoverages.map(({ title, description }) => (
        <Text key={title}>
          {title} {description.join(', ')}
        </Text>
      ))}
    </Row>
    {policy.coverages.baseCoverages.map(({ title, description }) => (
      <Row key={title}>
        <Text>{title}</Text>
        <Text className="col-span-3">{description.join(', ')}</Text>
      </Row>
    ))}
    {policy.coverages.otherCoverages.map(({ title, description }) => (
      <Row key={title}>
        <Text>{title}</Text>
        <Text className="col-span-3">{description.join(', ')}</Text>
      </Row>
    ))}
  </>
);

const Row: React.FC = ({ children }) => (
  <div className="grid grid-cols-4 gap-3 text-sm text-slate py-3 border-b border-cloudy-blue last:border-none">
    {children}
  </div>
);

export default PolicyInfo;

import { GetUserPolicyListQuery } from '@graphql/types';
import { sortPoliciesByDate } from '@helpers/policyHelpers';
import { UserPolicy } from './types';
import { PolicyStatus } from './constants';
import { Vehicle } from '@utilV2/domain/vehicle';
import Dinero from '@utilV2/dinero';
import Datos from '@utilV2/datos';
import Metros from '@utilV2/metros';

const getUserPolicyListSelector = ({
  user,
}: GetUserPolicyListQuery): UserPolicy[] | undefined => {
  if (user.policies.length === 0) {
    return undefined;
  }

  const userPolicies = sortPoliciesByDate(user.policies);

  return userPolicies.map(policy => ({
    id: policy.id,
    policyNumber: policy.policyNumberDetails.value,
    packageName: policy.quoteV2.selectedPackage?.content.packageName ?? '',
    policyholder: {
      name: `${policy.policyholder.name.firstName} ${policy.policyholder.name.lastName}`,
      address: {
        line1: policy.policyholder.address.addressLine1,
        line2: policy.policyholder.address.addressLine2 ?? null,
        city: policy.policyholder.address.city,
        state: policy.policyholder.address.state,
        zipCode: policy.policyholder.address.zipCode,
      },
      email: policy.policyholder.email,
    },
    status: PolicyStatus.fromApi(policy.status),
    term: Datos.fromISOInterval(policy.term),
    vehicles: policy.vehicles.map(
      ({ vehicleDetails: { id, year, make, model, vin } }) =>
        Vehicle.create({ id, year, make, model, vin }),
    ),
    premium: {
      perDay: Dinero.from(policy.totalPremium.perDay ?? 0),
      perMile: Dinero.from(policy.totalPremium.perMile),
    },
    quote: {
      estimatedMonthlyMileage: Metros.fromMi(
        policy.quoteV2.estimatedMonthlyMileage,
      ),
      estimatedMonthlyCost: Dinero.from(
        policy.quoteV2.selectedPackage?.total.estimatedMonthlyCost ?? 0,
      ),
    },
    coverages: {
      baseCoverages:
        policy.quoteV2.selectedPackage?.content.summary.baseCoverages.map(
          ({ title, description }) => ({
            title,
            description,
          }),
        ) ?? [],
      includedCoverages:
        policy.quoteV2.selectedPackage?.content.summary.includedCoverages.map(
          ({ title, description }) => ({
            title,
            description,
          }),
        ) ?? [],
      otherCoverages:
        policy.quoteV2.selectedPackage?.content.summary.coveragesWithOptions.map(
          ({ title, description }) => ({
            title,
            description,
          }),
        ) ?? [],
    },
  }));
};

export default getUserPolicyListSelector;

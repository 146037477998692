import { PolicyStatus } from '../../constants';
import { UserPolicy } from '../../types';
import PolicyCard from '../PolicyCard/PolicyCard';
import PolicyNumber from '../PolicyCard/PolicyNumber';
import PolicyCardHeader from '../PolicyCard/PolicyCardHeader';
import PolicyCardContent from '../PolicyCard/PolicyCardContent';
import PolicyInfo from '../PolicyCard/PolicyInfo';
import SideDrawer from '@componentsV2/SideDrawer';
import FullPolicyBreakdown from '../common/FullPolicyBreakdown';
import { useSideDrawer } from '@componentsV2/SideDrawer/useSideDrawer';

type Props = {
  policy: UserPolicy;
};

const EndedPolicy: React.FC<Props> = ({ policy }) => {
  const fullPolicyBreakdownDrawer = useSideDrawer();

  return (
    <>
      <PolicyNumber
        policyNumber={policy.policyNumber}
        policyCardType="ended"
        label={PolicyStatus.toString(policy.status)}
        className="mb-3"
      />
      <PolicyCard>
        <PolicyCardHeader policy={policy} policyCardType="ended" />
        <PolicyCardContent>
          <PolicyInfo
            policy={policy}
            policyCardType="ended"
            onViewFullBreakdown={() =>
              fullPolicyBreakdownDrawer.open({ title: 'Full policy breakdown' })
            }
          />
        </PolicyCardContent>
      </PolicyCard>
      <SideDrawer id={fullPolicyBreakdownDrawer.id}>
        <FullPolicyBreakdown policy={policy} />
      </SideDrawer>
    </>
  );
};

export default EndedPolicy;

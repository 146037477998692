import parsePhoneNumber from 'libphonenumber-js';
import { GetUserOverviewQuery } from '@graphql/types';
import { UserOverview } from './types';
import { sortPoliciesByDate } from '@helpers/policyHelpers';
import { AccountState } from '@pagesV2/UserPage/types';
import Dinero from '@utilV2/dinero';
import Datos from '@utilV2/datos';
import { Vehicle } from '@utilV2/domain/vehicle';

const getUserOverviewSelector = (
  { user }: GetUserOverviewQuery,
  accountState: AccountState,
): UserOverview | undefined => {
  if (user.policies.length === 0) {
    return undefined;
  }

  const userPolicies = sortPoliciesByDate(user.policies);
  const phoneNumber = user.phoneNumber
    ? parsePhoneNumber(user.phoneNumber)?.formatNational()
    : undefined;

  const address = userPolicies[0].policyholder.address;

  return {
    contact: {
      name: `${userPolicies[0].policyholder.name.firstName} ${userPolicies[0].policyholder.name.lastName}`,
      email: userPolicies[0].policyholder.email,
      phoneNumber,
      address: {
        line1: address.addressLine1,
        line2: address.addressLine2 ?? null,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
      },
    },
    driving: {
      vehicles: userPolicies[0].vehicles.map(
        ({ vehicleDetails: { id, make, model, vin, year } }) =>
          Vehicle.create({ id, make, model, vin, year }),
      ),
      driverLicenseNumber:
        userPolicies[0].quoteV2.insuranceApplication?.personalDetails
          .driverLicense.number,
      dob: Datos.fromISODate(userPolicies[0].policyholder.dob),
      autoTripEnabled:
        user.betaFlagContent.flags?.find(flags => flags.key === 'isAutoTrip')
          ?.value ?? false,
    },
    balance: {
      currentBalance: Dinero.from(user.wallet.balance),
      deposit: Dinero.from(
        user.wallet.automaticPayments?.refill.threshold ??
          user.wallet.automaticPaymentConfig.defaultRefillThreshold,
      ),
      totalFunds: Dinero.from(user.wallet.funds),
      autoPayEnabled: user.wallet.automaticPayments?.isEnabled ?? false,
    },
    accountStatus: {
      eligible: user.underwriting.account?.state.isRestricted ?? true,
      blocked:
        user.underwriting.account?.state.__typename === 'SuspendedState' ??
        false,
      accountState,
    },
  };
};

export default getUserOverviewSelector;

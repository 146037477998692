import { PolicyStatus } from '../../constants';
import { UserPolicy } from '../../types';
import PolicyCard from '../PolicyCard/PolicyCard';
import PolicyNumber from '../PolicyCard/PolicyNumber';
import PolicyCardHeader from '../PolicyCard/PolicyCardHeader';
import PolicyCardContent from '../PolicyCard/PolicyCardContent';
import PolicyInfo from '../PolicyCard/PolicyInfo';
import PolicyOptions from './PolicyOptions';
import { useSideDrawer } from '@componentsV2/SideDrawer/useSideDrawer';
import SideDrawer from '@componentsV2/SideDrawer';
import UpdatePolicyStartDate from './Drawers/UpdatePolicyStartDate';
import VoidFuturePolicy from './Drawers/VoidFuturePolicy';
import FullPolicyBreakdown from '../common/FullPolicyBreakdown';

type Props = {
  userId: string;
  policy: UserPolicy;
  onPolicyChange: () => void;
};

const FuturePolicy: React.FC<Props> = ({ userId, policy, onPolicyChange }) => {
  const policyStartDateDrawer = useSideDrawer();
  const voidPolicyDrawer = useSideDrawer();
  const fullPolicyBreakdownDrawer = useSideDrawer();

  return (
    <>
      <PolicyNumber
        policyNumber={policy.policyNumber}
        policyCardType="future"
        label={PolicyStatus.toString(policy.status)}
        className="mb-3"
      />
      <PolicyCard>
        <PolicyCardHeader policy={policy} policyCardType="future" />
        <PolicyCardContent>
          <PolicyInfo
            policy={policy}
            policyCardType="future"
            onViewFullBreakdown={() =>
              fullPolicyBreakdownDrawer.open({ title: 'Full policy breakdown' })
            }
          />
          <PolicyOptions
            onChangeStartDate={() =>
              policyStartDateDrawer.open({ title: 'Change start date' })
            }
            onVoidPolicy={() =>
              voidPolicyDrawer.open({
                title: 'Void future policy',
                onClose: onPolicyChange,
              })
            }
          />
        </PolicyCardContent>
      </PolicyCard>
      <SideDrawer id={policyStartDateDrawer.id}>
        <UpdatePolicyStartDate
          currentStartDate={policy.term.start}
          userId={userId}
          policyId={policy.id}
          onClose={() => policyStartDateDrawer.close()}
          onSuccess={onPolicyChange}
        />
      </SideDrawer>
      <SideDrawer id={voidPolicyDrawer.id}>
        <VoidFuturePolicy
          userId={userId}
          policyId={policy.id}
          policyNumber={policy.policyNumber}
          onClose={() => voidPolicyDrawer.close()}
        />
      </SideDrawer>
      <SideDrawer id={fullPolicyBreakdownDrawer.id}>
        <FullPolicyBreakdown policy={policy} />
      </SideDrawer>
    </>
  );
};

export default FuturePolicy;

import Datos from '@utilV2/datos';
import { z } from 'zod';

export const schema = z.object({
  startDate: z
    .custom<Datos>()
    .refine(val => val && val.isValid, {
      message: 'Invalid date',
    })
    .refine(val => val >= Datos.today(), {
      message: 'Start date must be in the future',
    }),
});

export type FormValues = z.infer<typeof schema>;

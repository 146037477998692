import { UserPolicy } from '../types';
import { PolicyStatus } from '../constants';
import FuturePolicy from './FuturePolicy';
import ActivePolicy from './ActivePolicy';
import EndedPolicy from './EndedPolicy';

type Props = {
  userId: string;
  policy: UserPolicy;
  className?: string;
  onPolicyChange: () => void;
};

const Policy: React.FC<Props> = ({
  userId,
  policy,
  className,
  onPolicyChange,
}) => (
  <div className={className}>
    {(() => {
      switch (policy.status) {
        case PolicyStatus.Active:
          return <ActivePolicy userId={userId} policy={policy} />;
        case PolicyStatus.Future:
          return (
            <FuturePolicy
              userId={userId}
              policy={policy}
              onPolicyChange={onPolicyChange}
            />
          );
        case PolicyStatus.Cancelled:
        case PolicyStatus.Expired:
        case PolicyStatus.Void:
          return <EndedPolicy policy={policy} />;
      }
    })()}
  </div>
);

export default Policy;

import Divider from '@componentsV2/Divider';
import CancelByCarrier from './CancelByCarrier';
import RescindPolicy from './RescindPolicy';

type Props = {
  userId: string;
  policyNumber: string;
  onClose: () => void;
};

const CancelPolicy: React.FC<Props> = ({ userId, policyNumber, onClose }) => {
  return (
    <>
      <CancelByCarrier
        userId={userId}
        policyNumber={policyNumber}
        onClose={onClose}
      />
      <Divider className="my-6" />
      <RescindPolicy
        userId={userId}
        policyNumber={policyNumber}
        onClose={onClose}
      />
    </>
  );
};

export default CancelPolicy;

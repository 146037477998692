import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Ref } from 'react';
import { twMerge } from 'tailwind-merge';

export type Option<T> = {
  label: string;
  value: T;
};

type Props<T> = {
  options: Option<T>[];
  value: T | undefined;
  onChange: (value: T) => void;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  inputRef?: Ref<HTMLElement>;
};

const Select = <T,>({
  options,
  value,
  onChange,
  name,
  placeholder,
  disabled,
  className,
  inputRef,
}: Props<T>) => {
  // display selected option label, otherwise display placeholder if no selected option
  const label =
    options.find(option => option.value === value)?.label ?? placeholder;

  return (
    <Listbox
      value={value}
      onChange={onChange}
      disabled={disabled}
      name={name}
      ref={inputRef}
    >
      <div className="relative mt-2">
        <ListboxButton
          className={twMerge(
            'relative w-full cursor-default rounded-md bg-white py-2 pl-2 pr-10 text-left text-slate focus:border-teal text-sm border border-light-grey',
            className,
            disabled ? 'bg-pale-grey' : '',
          )}
        >
          <span className="block truncate">{label}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              aria-hidden="true"
              className="size-5 text-blue-grey"
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in text-sm"
        >
          {options.map(option => (
            <ListboxOption
              key={option.label}
              value={option.value}
              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-slate data-[focus]:bg-teal/20"
            >
              <span className="block truncate font-normal group-data-[selected]:font-bold">
                {option.label}
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
};

export default Select;

import { UserPolicy } from '../../types';
import { TextHeading, Text } from '@componentsV2/Typography';
import { addressToString } from '@helpers/addressHelpers';
import Divider from '@componentsV2/Divider';
import Datos from '@utilV2/datos';

type Props = {
  policy: UserPolicy;
};

const FullPolicyBreakdown: React.FC<Props> = ({ policy }) => {
  return (
    <>
      <SectionHeading>Named Insured</SectionHeading>
      <Row>
        <TextHeading>Name</TextHeading>
        <Text>{policy.policyholder.name}</Text>
      </Row>
      <Row>
        <TextHeading>Address</TextHeading>
        <Text>{addressToString(policy.policyholder.address)}</Text>
      </Row>
      <Row>
        <TextHeading>Email address</TextHeading>
        <Text>{policy.policyholder.email}</Text>
      </Row>
      <Divider className="my-3" />
      <SectionHeading>Vehicles</SectionHeading>
      <div>
        {policy.vehicles.map(vehicle => (
          <>
            <div key={vehicle.vin}>
              <Row>
                <TextHeading>Year, make & model</TextHeading>
                <Text>
                  {vehicle.year}, {vehicle.make}, {vehicle.model}
                </Text>
              </Row>
              <Row>
                <TextHeading>VIN</TextHeading>
                <Text>{vehicle.vin}</Text>
              </Row>
            </div>
            <Divider variant="dashed" className="my-3 last:hidden" />
          </>
        ))}
      </div>
      <Divider className="my-3" />
      <SectionHeading>Policy coverage</SectionHeading>
      <Row>
        <TextHeading>Coverage type</TextHeading>
        <Text>{policy.packageName}</Text>
      </Row>
      <Row>
        <TextHeading>Estimated cost</TextHeading>
        <Text>{policy.quote.estimatedMonthlyCost.toFormat()} per-month</Text>
      </Row>
      <Row>
        <TextHeading>Estimated mileage</TextHeading>
        <Text>
          {policy.quote.estimatedMonthlyMileage.miles} miles per-month
        </Text>
      </Row>
      <Row>
        <TextHeading>Cost breakdown</TextHeading>
        <Text>
          {policy.premium.perDay.toFormat()} per-day +{' '}
          {policy.premium.perMile.toFormat()} per-mile
        </Text>
      </Row>
      <Row>
        <TextHeading>Start date</TextHeading>
        <Text>{policy.term.start.toFormat(Datos.Formats.US_DATE)}</Text>
      </Row>
      <Row>
        <TextHeading>What’s included</TextHeading>
        {policy.coverages.includedCoverages.map(({ title, description }) => (
          <Text key={title}>
            {title} {description.join(', ')}
          </Text>
        ))}
      </Row>
      {policy.coverages.baseCoverages.map(({ title, description }) => (
        <Row key={title}>
          <TextHeading>{title}</TextHeading>
          {description.map(desc => (
            <Text key={desc}>{desc}</Text>
          ))}
        </Row>
      ))}
      <Row>
        <TextHeading>Optional extra</TextHeading>
        {policy.coverages.otherCoverages.length > 0
          ? policy.coverages.otherCoverages.map(({ title, description }) => (
              <Text key={title}>
                {title} {description.join(', ')}
              </Text>
            ))
          : '-'}
      </Row>
    </>
  );
};

const SectionHeading: React.FC = ({ children }) => (
  <TextHeading className="text-lg mb-2">{children}</TextHeading>
);

const Row: React.FC = ({ children }) => <div className="mb-2">{children}</div>;

export default FullPolicyBreakdown;

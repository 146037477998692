/**
 * Value object that holds logic of converting distance value between km and miles
 */
export default class Metros {
  private constructor(private milesValue: number) {}

  /**
   * Returns current distance value in miles
   * formatted to string
   */
  get miles(): string {
    return format(this.miValue);
  }

  /**
   * Returns current distance value in kilometers
   * formatted to string
   */
  get kilometers(): string {
    return format(this.kmValue);
  }

  /**
   * Returns rounded value of current distance in kilometers
   * Rounded to two decimal places
   */
  get kmValue(): number {
    return round(milesToKm(this.milesValue));
  }

  /**
   * Returns rounded value of current distance in miles
   * Rounded to two decimal places
   */
  get miValue(): number {
    return round(this.milesValue);
  }

  static fromKm(km: number): Metros {
    return new Metros(kmToMiles(km));
  }

  static fromMi(miles: number): Metros {
    return new Metros(miles);
  }
}

/**
 * 1 Kilometre = 0.621371 Miles
 * 1 Mile = 1.609344 Kilometer
 */
const KM_IN_MILES = 1.609344;
function milesToKm(miles: number): number {
  return miles * KM_IN_MILES;
}
function kmToMiles(km: number): number {
  return km / KM_IN_MILES;
}

/**
 * Rounds the value to two decimal places
 */
function round(value: number): number {
  return Number(value.toFixed(2));
}

/**
 * Formats the value to readable string, optionally omitting decimal zeros
 */
function format(value: number, skipDecimalZero: boolean = true): string {
  const isInteger = value % 1 === 0;

  if (isInteger && skipDecimalZero) {
    return value.toString();
  }

  return value.toFixed(2);
}

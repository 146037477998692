import { twMerge } from 'tailwind-merge';

type Props = {
  cols?: number;
  className?: string;
};

const PolicyCardOptions: React.FC<Props> = ({
  children,
  className,
  cols = 1,
}) => {
  return (
    <div className={twMerge(`grid gap-2 mt-3 grid-cols-${cols}`, className)}>
      {children}
    </div>
  );
};

export default PolicyCardOptions;

import { PolicyStatus } from '../../constants';
import { UserPolicy } from '../../types';
import PolicyCard from '../PolicyCard/PolicyCard';
import PolicyNumber from '../PolicyCard/PolicyNumber';
import PolicyCardHeader from '../PolicyCard/PolicyCardHeader';
import PolicyCardContent from '../PolicyCard/PolicyCardContent';
import PolicyInfo from '../PolicyCard/PolicyInfo';
import SideDrawer from '@componentsV2/SideDrawer';
import { useSideDrawer } from '@componentsV2/SideDrawer/useSideDrawer';
import CancelPolicy from './Drawers/CancelPolicy';
import PolicyCardOptions from '../PolicyCard/PolicyCardOptions';
import { CancelButton } from '../PolicyCard/Buttons';
import FullPolicyBreakdown from '../common/FullPolicyBreakdown';

type Props = {
  userId: string;
  policy: UserPolicy;
};

const ActivePolicy: React.FC<Props> = ({ userId, policy }) => {
  const cancelPolicyDrawer = useSideDrawer();
  const fullPolicyBreakdownDrawer = useSideDrawer();

  return (
    <>
      <PolicyNumber
        policyNumber={policy.policyNumber}
        policyCardType="active"
        label={PolicyStatus.toString(policy.status)}
        className="mb-3"
      />
      <PolicyCard>
        <PolicyCardHeader policy={policy} policyCardType="active" />
        <PolicyCardContent>
          <PolicyInfo
            policy={policy}
            policyCardType="active"
            onViewFullBreakdown={() =>
              fullPolicyBreakdownDrawer.open({ title: 'Full policy breakdown' })
            }
          />
          <PolicyCardOptions>
            <CancelButton
              onClick={() =>
                cancelPolicyDrawer.open({
                  title: 'Cancel or rescind policy',
                })
              }
            >
              Cancel or rescind policy
            </CancelButton>
          </PolicyCardOptions>
        </PolicyCardContent>
      </PolicyCard>
      <SideDrawer id={cancelPolicyDrawer.id}>
        <CancelPolicy
          userId={userId}
          policyNumber={policy.policyNumber}
          onClose={() => cancelPolicyDrawer.close()}
        />
      </SideDrawer>
      <SideDrawer id={fullPolicyBreakdownDrawer.id}>
        <FullPolicyBreakdown policy={policy} />
      </SideDrawer>
    </>
  );
};

export default ActivePolicy;

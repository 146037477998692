import { Text } from '@componentsV2/Typography';
import Button from '@componentsV2/Button';
import ButtonGroup from '@componentsV2/ButtonGroup';
import { useVoidFuturePolicyMutation } from '@graphql/api';
import { logError, ErrorCode } from '@services/logging';
import StatusAlert from '@componentsV2/StatusAlert';

type Props = {
  userId: string;
  policyId: string;
  policyNumber: string;
  onClose: () => void;
};

const VoidFuturePolicy: React.FC<Props> = ({
  userId,
  policyId,
  policyNumber,
  onClose,
}) => {
  const {
    mutate: voidFuturePolicy,
    isLoading,
    isError,
    isSuccess,
  } = useVoidFuturePolicyMutation({
    onError: (e: Error) => logError(ErrorCode.VoidFuturePolicy, e),
  });

  return (
    <>
      <Text>
        Please confirm the voiding of policy{' '}
        <span className="font-bold">{policyNumber}</span>
      </Text>
      <ButtonGroup className="mt-6">
        <Button
          variant="alert"
          disabled={isLoading}
          onClick={() => voidFuturePolicy({ input: { policyId, userId } })}
        >
          Void policy
        </Button>
        <Button variant="transparent" onClick={onClose}>
          Cancel
        </Button>
      </ButtonGroup>
      <StatusAlert
        status={
          isLoading
            ? 'info'
            : isError
            ? 'error'
            : isSuccess
            ? 'success'
            : undefined
        }
        infoMessage="Voiding future policy..."
        errorMessage="There was an error voiding future policy."
        successMessage="Successfully voided future policy."
        className="mt-6"
      />
    </>
  );
};

export default VoidFuturePolicy;

import { EditButton, CancelButton } from '../PolicyCard/Buttons';
import PolicyCardOptions from '../PolicyCard/PolicyCardOptions';

type Props = {
  onChangeStartDate: () => void;
  onVoidPolicy: () => void;
};

const PolicyOptions: React.FC<Props> = ({
  onChangeStartDate,
  onVoidPolicy,
}) => {
  return (
    <PolicyCardOptions className="grid-cols-2">
      <EditButton onClick={onChangeStartDate}>Change start date</EditButton>
      <CancelButton onClick={onVoidPolicy}>Void policy</CancelButton>
    </PolicyCardOptions>
  );
};

export default PolicyOptions;

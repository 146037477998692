import { default as BaseCalendar } from 'react-calendar';
import { Detail } from 'react-calendar/dist/cjs/shared/types';
import { twMerge } from 'tailwind-merge';
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
} from '@heroicons/react/24/outline';
import Datos from '@utilV2/datos';

type Props = {
  startDate?: Datos;
  endDate?: Datos;
  minDetail?: Detail;
  selectedDate?: Datos;
  showYearSelection?: boolean;
  onChange: (date: Datos) => void;
  className?: string;
};

const Calendar: React.FC<Props> = ({
  selectedDate,
  onChange,
  startDate,
  endDate,
  minDetail = 'month',
  showYearSelection = false,
  className,
}) => (
  <div className={twMerge('max-w-xs', className)}>
    <BaseCalendar
      prevLabel={<ChevronLeftIcon className="text-slate h-4 w-4" />}
      nextLabel={<ChevronRightIcon className="text-slate h-4 w-4" />}
      prev2Label={
        showYearSelection ? (
          <ChevronDoubleLeftIcon className="text-slate h-4 w-4" />
        ) : null
      }
      next2Label={
        showYearSelection ? (
          <ChevronDoubleRightIcon className="text-slate h-4 w-4" />
        ) : null
      }
      value={selectedDate?.isValid ? selectedDate.toJSDate() : undefined}
      onClickDay={value => onChange(Datos.fromJSDate(value))}
      minDate={startDate?.isValid ? startDate.toJSDate() : undefined}
      maxDate={endDate?.isValid ? endDate.toJSDate() : undefined}
      minDetail={minDetail}
    />
  </div>
);

export default Calendar;

import Select from '@componentsV2/Form/Select';
import { useGetPolicyCancellationReasonsQuery } from '@graphql/api';
import { cancellationReasonsSelector } from './cancellationReasonsSelector';
import { ErrorCode, logError } from '@services/logging';
import ErrorMessage from '@componentsV2/Inputs/ErrorMessage';

type Props = {
  className?: string;
};

const CancellationReasonsSelect: React.FC<Props> = ({ className }) => {
  const {
    isLoading,
    isError,
    data: cancellationReasonOptions,
  } = useGetPolicyCancellationReasonsQuery(
    {},
    {
      select: cancellationReasonsSelector,
      onError: (e: Error) =>
        logError(ErrorCode.UserPolicyCancellationReasons, e),
    },
  );

  return (
    <div className={className}>
      <Select
        name="cancellationExplanationCode"
        options={cancellationReasonOptions || []}
        disabled={isLoading || isError}
        placeholder={isLoading ? 'Loading...' : 'Cancellation reason'}
      />
      {isError ? (
        <ErrorMessage
          message="Error fetching cancellation reasons"
          className="mt-1"
        />
      ) : null}
    </div>
  );
};

export default CancellationReasonsSelect;

enum DineroFormat {
  Regular = 'regular', // $20.00
  Short = 'short', // $20
  WithCent = 'withCent', // $20 ¢00
  WithoutDollar = 'withoutDollar', // 20.00
}

class Dinero {
  static Formats = DineroFormat;

  private constructor(private valueInCents: number) {}

  static from(cents: number): Dinero {
    return new Dinero(cents);
  }

  static fromString(value: string | null): Dinero {
    if (!value) {
      return new Dinero(0);
    }

    const amountInCents = Math.round(
      Number(value.replace(/[^0-9.-]+/g, '')) * 100,
    );

    return new Dinero(amountInCents);
  }

  toCents(): number {
    return this.valueInCents;
  }

  toFormat(format: DineroFormat = DineroFormat.Regular): string {
    const valueInDollars = this.valueInCents / 100;

    switch (format) {
      case DineroFormat.Regular:
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }).format(valueInDollars);

      case DineroFormat.Short:
        if (this.valueInCents % 100 === 0) {
          // If no cents return just dollars
          return `$${Math.floor(valueInDollars)}`;
        } else {
          // Include cents up to 2 decimal places
          return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
          }).format(valueInDollars);
        }

      case DineroFormat.WithCent: {
        const dollars = Math.floor(valueInDollars);
        const cents = (this.valueInCents % 100).toString().padStart(2, '0');
        return `$${dollars} ${cents}¢`;
      }
      case DineroFormat.WithoutDollar:
        return valueInDollars.toFixed(2);

      default:
        throw new Error('Invalid format');
    }
  }

  add(amount: Dinero | number): Dinero {
    if (typeof amount === 'number') {
      return new Dinero(this.valueInCents + amount);
    }
    return new Dinero(this.valueInCents + amount.toCents());
  }

  subtract(amount: Dinero | number): Dinero {
    if (typeof amount === 'number') {
      return new Dinero(this.valueInCents - amount);
    }
    return new Dinero(this.valueInCents - amount.toCents());
  }

  greaterThan(amount: Dinero | number): boolean {
    const valueInCents = typeof amount === 'number' ? amount : amount.toCents();
    return this.valueInCents > valueInCents;
  }

  greaterThanOrEqual(amount: Dinero | number): boolean {
    const valueInCents = typeof amount === 'number' ? amount : amount.toCents();
    return this.valueInCents >= valueInCents;
  }

  lessThan(amount: Dinero | number): boolean {
    const valueInCents = typeof amount === 'number' ? amount : amount.toCents();
    return this.valueInCents < valueInCents;
  }

  lessThanOrEqual(amount: Dinero | number): boolean {
    const valueInCents = typeof amount === 'number' ? amount : amount.toCents();
    return this.valueInCents <= valueInCents;
  }

  abs(): Dinero {
    return new Dinero(Math.abs(this.valueInCents));
  }

  min(amount: Dinero | number): Dinero {
    return new Dinero(
      Math.min(
        this.valueInCents,
        amount instanceof Dinero ? amount.toCents() : amount,
      ),
    );
  }

  equals(amount: Dinero | number): boolean {
    return (
      this.valueInCents ===
      (amount instanceof Dinero ? amount.toCents() : amount)
    );
  }
}

export default Dinero;

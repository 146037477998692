import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
};

const ButtonGroup: React.FC<Props> = ({ children, className }) => (
  <div className={twMerge('flex gap-2', className)}>{children}</div>
);

export default ButtonGroup;

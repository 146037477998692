import { twMerge } from 'tailwind-merge';
import { UserPolicy } from '../../types';
import { DisclosureButton } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { PolicyCardType } from './types';
import Datos from '@utilV2/datos';

type Props = {
  policy: UserPolicy;
  policyCardType: PolicyCardType;
};

const PolicyCardHeader: React.FC<Props> = ({ policy, policyCardType }) => (
  <dt>
    <DisclosureButton
      className={twMerge(
        'border-b px-4 py-3 rounded-t-lg group w-full',
        policyCardType === 'active'
          ? 'bg-pale-green text-green border-green'
          : '',
        policyCardType === 'future'
          ? 'bg-pale-orange text-orange border-orange'
          : '',
        policyCardType === 'ended'
          ? 'bg-pale-grey text-slate border-slate'
          : '',
      )}
    >
      <div className="mb-2 flex text-sm items-center">
        <div className="flex flex-1">
          <p className="font-bold mr-2">{policy.packageName}:</p>
          <p>
            {policy.term.start.toFormat(Datos.Formats.US_DATE)} -{' '}
            {policy.term.end.toFormat(Datos.Formats.US_DATE)}
          </p>
        </div>
        <span className="ml-2 flex h-4 items-center">
          <ChevronDownIcon
            aria-hidden="true"
            className={twMerge(
              'h-4 w-4 group-data-[open]:rotate-180',
              policyCardType === 'active' ? 'text-green' : '',
              policyCardType === 'future' ? 'text-orange' : '',
              policyCardType === 'ended' ? 'text-slate' : '',
            )}
          />
        </span>
      </div>
      <div className="flex text-xs text-slate">
        <div>
          {policy.vehicles.map(({ year, make, model, vin }) => (
            <p key={vin}>
              {year}, {make}, {model}
            </p>
          ))}
        </div>
        <div className="mx-2">–</div>
        <p>
          {policy.premium.perMile.toFormat()} per-mile /{' '}
          {policy.premium.perDay.toFormat()} per-day
        </p>
      </div>
    </DisclosureButton>
  </dt>
);

export default PolicyCardHeader;

import { GetPolicyCancellationReasonsQuery } from '@graphql/api';

export const cancellationReasonsSelector = ({
  underwriting,
}: GetPolicyCancellationReasonsQuery) => {
  return underwriting.cancellationReasons.map(reason => ({
    label: reason.message,
    value: reason.code,
  }));
};
